import { AUTH_BY_TOKEN_URL, LEGACY_APP_ROOT } from './constants';
import { clearUserLocalStorageAndSession } from './helpers';

type ResponseT = {
  status: number;
  statusText: string;
  json: Function;
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: ResponseT) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response: ResponseT) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(`HTTP Error ${response.statusText}`);
  // @ts-ignore TODO: response isn't a normal property of Error, are we using this?
  error.response = response;
  throw error;
}

interface RequestHeaders {
  Authorization?: string;
  'Content-Type'?: string;
}

interface RequestOptions {
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE';
  headers?: RequestHeaders;
  body?: any;
}

const buildHeaders = (
  headersOverride: RequestHeaders = {},
  url: string,
): RequestHeaders => {
  const headers = {
    'Content-Type': 'application/json',
    ...headersOverride,
  };
  const jwtToken = window.localStorage.getItem('jwtToken');

  if (!url.includes(AUTH_BY_TOKEN_URL) && jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }

  return headers;
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url: string, options: RequestOptions = {}) {
  const normalizedOptions: RequestOptions = {
    method: options.method || 'GET',
    headers: buildHeaders(options.headers, url),
  };

  if (options.body) {
    normalizedOptions.body = options.body;
  }

  return fetch(url, normalizedOptions as RequestInit)
    .then(checkStatus)
    .then(parseJSON)
    .catch(checkStatus);
}

/**
 * Builds and returns a redirect URL
 * @param {string} relativeStorePath relative path of store, defaults to /logout if not provided
 * @return {string} The redirect url
 */
export function buildInvalidateUrl(relativeStorePath: string = '/logout') {
  clearUserLocalStorageAndSession();
  let newLocation = `${LEGACY_APP_ROOT}${relativeStorePath}`;
  const redirect = encodeURIComponent(window.location.href);
  newLocation += `?my_results_redirect=${redirect}`;
  return newLocation;
}
