import { useTelehealthAppointments } from 'common/apis/telehealthApis';
import useUser from 'common/hooks/useUser';
import { isUserMemberOfProgram } from 'common/utils/user';

interface UseHasVcvEmptyStateReturn {
  hasVcvEmptyState: boolean;
  showVirtualCareLink: boolean;
  showVirtualCareCalloutBox: boolean;
}

/**
 * Determines if the Virtual Care Visit (VCV) empty state should be shown.
 * This function:
 * - Fetches future and past appointments automatically
 * - Checks EW360, WC+, and EW+ membership status
 * - Determines whether to show or hide the Virtual Care section
 * - Determines if the Virtual Care link should be displayed in the left navigation
 * - Determines if the Virtual Care callout box should be shown on the My Everly dashboard
 *
 * @param user - The user object
 * @returns {UseHasVcvEmptyStateReturn} An object containing:
 *   - hasVcvEmptyState: boolean (true if the VCV empty state should be shown)
 *   - showVirtualCareLink: boolean (true if the Virtual Care link should be displayed)
 *   - showVirtualCareCalloutBox: boolean (true if the Virtual Care callout box should be displayed)
 */

export function useHasVcvEmptyState(): UseHasVcvEmptyStateReturn {
  const { user, refetch: refetchUser } = useUser();
  const jwtToken = window.localStorage.getItem('jwtToken');

  // Fetch Future Appointments
  const {
    data: futureAppointmentsResponse,
    isLoading: isLoadingFutureAppointments,
    isSuccess: isFutureAppointmentsSuccess,
    isError: isFutureAppointmentsError,
    refetch: refetchFutureAppointments,
  } = useTelehealthAppointments(
    { period: 'future', limit: 1, sort_by: 'date_asc' },
    {
      enabled: !!user && Boolean(jwtToken),
      retry: (failureCount) => failureCount < 3,
      retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 10000),
    },
  );

  // Fetch Past Appointments
  const {
    data: pastAppointmentsResponse,
    isLoading: isLoadingPastAppointments,
    isSuccess: isPastAppointmentsSuccess,
    isError: isPastAppointmentsError,
    refetch: refetchPastAppointments,
  } = useTelehealthAppointments(
    { period: 'past', limit: 1, sort_by: 'date_asc' },
    {
      enabled: !!user && Boolean(jwtToken),
      retry: (failureCount) => failureCount < 3,
      retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 10000),
    },
  );

  // Check for errors and refetch if necessary
  if (isFutureAppointmentsError || isPastAppointmentsError) {
    refetchUser().then(() => {
      refetchFutureAppointments();
      refetchPastAppointments();
    });
  }

  // Extract appointment data
  const futureAppointments =
    futureAppointmentsResponse?.data?.appointments ?? [];
  const pastAppointments = pastAppointmentsResponse?.data?.appointments ?? [];

  const appointment =
    futureAppointments.length === 0
      ? pastAppointments[0]
      : futureAppointments[0];

  const hasAppointments = !!appointment;

  const isLoadingAppointments =
    isLoadingFutureAppointments || isLoadingPastAppointments;
  const isSuccess = isFutureAppointmentsSuccess || isPastAppointmentsSuccess;
  const isLoadingSuccessAndNoAppointments =
    !isLoadingAppointments && isSuccess && !appointment;

  // User Membership Checks
  const isWCPlusMember =
    user && isUserMemberOfProgram(user, ['weight_management']);
  const isEWPlusMember = user && isUserMemberOfProgram(user, ['control']);
  const isEW360Member = user && isUserMemberOfProgram(user, ['health360']);

  // Determine what to show
  const showVirtualCareLink =
    !isEW360Member || (isEW360Member && hasAppointments);
  const showVirtualCareCalloutBox = !isEW360Member && !hasAppointments;

  return {
    hasVcvEmptyState:
      isLoadingSuccessAndNoAppointments && !(isWCPlusMember || isEWPlusMember),
    showVirtualCareLink,
    showVirtualCareCalloutBox,
  };
}
