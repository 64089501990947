import { Button, LocalizeDisabled } from '@everlywell/leaves';
import {
  getCarePlanByFormAnswerGroupId,
  useTelehealthAppointments,
} from 'common/apis/telehealthApis';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { WWW_APP_ROOT } from 'common/utils/constants/urls';
import { telehealthUrl } from 'common/utils/helpers';
import { logError } from 'common/utils/helpers';
import { PROGRAM_TYPE } from 'common/utils/types';
import { User } from 'common/utils/types';
import { isUserMemberOfProgram } from 'common/utils/user';
import { useScrolledIntoViewOnce } from 'common/utils/useScrolledIntoViewOnce';
import {
  parseJSON,
  isFuture,
  parseISO,
  isBefore,
  sub,
  isAfter,
} from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AppointmentDate from 'pages/AccountHub/components/AppointmentDate';
import BaseCard, { BaseCardIcon } from 'pages/AccountHub/components/BaseCard';
import ManageAppointmentModal from 'pages/AccountHub/components/ManageAppointmentModal';
import {
  ON_DEMAND_CLINICIAN,
  ASYNC_CLINICIAN,
  UPCOMING_ASYNC_DESCRIPTION,
} from 'pages/AccountHub/pages/TelehealthDashboard/components/TelehealthAppointments/components/AppointmentCard';
import {
  isPmStatusOccurred,
  translateStatus,
} from 'pages/AccountHub/pages/TelehealthDashboard/components/TelehealthAppointments/utils';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import ErrorCard from '../../../../../components/ErrorCard';
import { CardHeaderLink } from '../BaseCard.styles';
import FutureAppointmentsCTA from './CTA';
import TelehealthIcon from './images/icon-telehealth.svg';
import TelehealthCardSkeleton from './TelehealthCard.skeleton';
import * as S from './TelehealthCard.styles';
import { useHasVcvEmptyState } from './telehealthUtils';

/**
 * Telehealth card for account hub dashboard.
 */

export type TelehealthCardProps = {
  user?: User;
};

function TelehealthCard({ user }: TelehealthCardProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { appointmentManagement } = useFlags();

  const jwtToken = window.localStorage.getItem('jwtToken');

  const isManageAppointmentModalEnabled = appointmentManagement;

  const {
    data: futureAppointmentsResponse,
    isLoading: isLoadingFutureAppointments,
    isError: isFutureAppointmentsError,
    isSuccess: isFutureAppointmentsSuccess,
    refetch: refetchFutureAppointments,
  } = useTelehealthAppointments(
    {
      period: 'future',
      limit: 1,
      sort_by: 'date_asc',
    },
    {
      enabled: !!user && Boolean(jwtToken),
      onError(error) {
        logError((error as Error).message, {
          errorInfo: 'Error Future Appointments',
          component: 'TelehealthCard',
          method: 'getFutureAppointments',
        });
      },
    },
  );

  const {
    data: pastAppointmentsResponse,
    isLoading: isLoadingPastAppointments,
    isError: isPastAppointmentsError,
    isSuccess: isPastAppointmentsSuccess,
    refetch: refetchPastAppointments,
  } = useTelehealthAppointments(
    {
      period: 'past',
      limit: 1,
      sort_by: 'date_desc',
    },
    {
      enabled: !!user && Boolean(jwtToken),
      onError(error) {
        logError((error as Error).message, {
          errorInfo: 'Error Past Appointments',
          component: 'TelehealthCard',
          method: 'getPastAppointments',
        });
      },
    },
  );

  const futureAppointments =
    futureAppointmentsResponse?.data?.appointments ?? [];
  const pastAppointments = pastAppointmentsResponse?.data?.appointments ?? [];

  const hasNoFutureAppointments = futureAppointments.length === 0;

  const appointment = hasNoFutureAppointments
    ? pastAppointments[0]
    : futureAppointments[0];

  const isAppointmentInTheFuture =
    appointment && isFuture(parseJSON(appointment.end_time));

  const program =
    appointment?.program.slug ?? PROGRAM_TYPE['virtual-care-visit'];

  const isAbleToJoinAppointment = () => {
    // a user can join an appointment if it's on demand
    if (appointment?.booking_type === 'on_demand') return true;
    const currentTime = new Date();
    // a user can join an appointment if it's a scheduled appointment
    // within 15 minutes of the start time
    const appointmentStart = sub(parseISO(appointment?.start_time), {
      minutes: 15,
    });
    const appointmentEnd = parseISO(appointment?.end_time);

    return (
      isAfter(currentTime, appointmentStart) &&
      isBefore(currentTime, appointmentEnd)
    );
  };

  const canJoinAppointment = isAbleToJoinAppointment();

  const parsedTelehealthUrl = telehealthUrl({
    program: program,
    redirectToProvider: 'true',
  });

  const carePlanId = appointment?.care_plan?.id;

  const {
    isError: isCarePlanError,
    refetch: refetchCarePlan,
    isLoading: isCarePlanLoading,
  } = useQuery(
    ['care-plan-data', carePlanId],
    () => getCarePlanByFormAnswerGroupId(carePlanId!),
    {
      enabled:
        Boolean(appointment) &&
        Boolean(carePlanId) &&
        Boolean(hasNoFutureAppointments),
    },
  );

  const bannerViewed = useScrolledIntoViewOnce(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
      data: {
        label: ANALYTICS.LABELS.ACCOUNT_HUB.TELEHEALTH_CARD,
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        appointment: !!appointment,
      },
    });
  });

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        label:
          hasNoFutureAppointments && !appointment
            ? ANALYTICS.LABELS.ACCOUNT_HUB.TELEHEALTH_CLICK
            : ANALYTICS.LABELS.ACCOUNT_HUB.TELEHEALTH_CLICK_LEARN_MORE,
        program: program,
      },
    });

    if (
      isAppointmentInTheFuture &&
      isManageAppointmentModalEnabled &&
      !canJoinAppointment
    ) {
      setOpenModal(true);
    }
  };

  const handleAppointmentsRetry = () => {
    if (isFutureAppointmentsError) {
      refetchFutureAppointments();
    }
    if (isPastAppointmentsError) {
      refetchPastAppointments();
    }
    if (isCarePlanError) {
      refetchCarePlan();
    }
  };

  const isLoadingAppointments =
    isLoadingFutureAppointments ||
    isLoadingPastAppointments ||
    isCarePlanLoading;
  const isError =
    isFutureAppointmentsError || isPastAppointmentsError || isCarePlanError;
  const isSuccess =
    isFutureAppointmentsSuccess &&
    isPastAppointmentsSuccess &&
    !isCarePlanError;

  const isOnDemandAppointment = appointment?.booking_type === 'on_demand';

  const isUpcomingOnDemandAppointment =
    isAppointmentInTheFuture && isOnDemandAppointment;
  const isVisible = isPmStatusOccurred(appointment?.status);

  const isWCPlusMember =
    user && isUserMemberOfProgram(user, ['weight_management']);
  const isEWPlusMember = user && isUserMemberOfProgram(user, ['control']);

  const isLoadingSuccessAndNoAppointments =
    !isLoadingAppointments && isSuccess && !appointment;

  const { hasVcvEmptyState, showVirtualCareCalloutBox, showVirtualCareLink } =
    useHasVcvEmptyState();
  const hasWCPlusEmptyState =
    isLoadingSuccessAndNoAppointments && isWCPlusMember;
  const hasEWPlusEmptyState =
    isLoadingSuccessAndNoAppointments && isEWPlusMember;

  const carePlanCompletedAt = appointment?.care_plan?.completed_at;
  const isAppointmentAsync = appointment?.booking_type === 'async';

  const asyncCarePlanDescription = carePlanCompletedAt ? (
    <>
      This review was completed on{' '}
      <AppointmentDate
        appointmentDate={carePlanCompletedAt}
        showTimezone={false}
      />
    </>
  ) : null;

  const setTitle = () => {
    if (isUpcomingOnDemandAppointment) {
      return ON_DEMAND_CLINICIAN;
    }
    if (isAppointmentAsync && !carePlanCompletedAt) {
      return ASYNC_CLINICIAN;
    }
    return `${appointment?.provider?.first_name} ${appointment?.provider?.last_name}`;
  };
  const title = setTitle();

  const setDescription = () => {
    if (isAppointmentAsync && !carePlanCompletedAt)
      return UPCOMING_ASYNC_DESCRIPTION;

    if (isAppointmentAsync && asyncCarePlanDescription)
      return asyncCarePlanDescription;

    if (isUpcomingOnDemandAppointment) {
      return "You will receive an email and a text reminder when it's time to join the appointment.";
    }

    return (
      <>
        {isPmStatusOccurred(appointment.status)
          ? `Your next appointment is on `
          : `Your appointment on `}
        <AppointmentDate
          appointmentDate={appointment.start_time}
          showTimezone
        />
        {!isPmStatusOccurred(appointment.status) &&
          ` was ${translateStatus(appointment.status)}`}
      </>
    );
  };

  return (
    <>
      <S.Container>
        {showVirtualCareLink && (
          <CardHeaderLink
            to={'/virtual-care'}
            component={Link}
            onClick={handleClick}
            //@ts-ignore TODO: refactor this component in leaves to receive generic props: https://everlyhealth.atlassian.net/browse/FE-591
            ref={bannerViewed}
          >
            Virtual Care
          </CardHeaderLink>
        )}

        {isLoadingAppointments && <TelehealthCardSkeleton />}

        {!isLoadingAppointments && isError && (
          <ErrorCard onRetry={handleAppointmentsRetry} />
        )}

        {/* show vcv empty state */}
        {hasVcvEmptyState && showVirtualCareCalloutBox && (
          <BaseCard
            image={<BaseCardIcon src={TelehealthIcon} alt="" />}
            content={{
              title: 'Virtual visits are now available',
              description: `Whether you have symptoms, need a second opinion, or
              don't know where to start—speak with a clinician for
              guidance.`,
            }}
            cta={
              <Button
                appearance="secondary"
                hasArrow
                href={`${WWW_APP_ROOT}/virtual-care-visit`}
                component={Link}
                onClick={handleClick}
              >
                Learn more
              </Button>
            }
          />
        )}

        {/* show everlywell plus empty state*/}
        {hasEWPlusEmptyState && (
          <BaseCard
            image={<BaseCardIcon src={TelehealthIcon} alt="" />}
            content={{
              title: 'Use your 20% off Virtual Visits',
              description: `As an Everlywell+ member, you receive 20% off all Virtual Care visits.
                Whether you have symptoms, need a second opinion, or don't know where to start-speak
                with a clinician for guidance.`,
            }}
            cta={
              <Button
                appearance="secondary"
                hasArrow
                to={`/telehealth/sessions/?program=${PROGRAM_TYPE['virtual-care-visit']}`}
                component={Link}
                onClick={handleClick}
              >
                Schedule
              </Button>
            }
          />
        )}

        {/* show WC+ empty state*/}
        {hasWCPlusEmptyState && (
          <BaseCard
            image={<BaseCardIcon src={TelehealthIcon} alt="" />}
            content={{
              title: 'Schedule your Weight Care+ Appointment',
              description:
                'Schedule a virtual visit with a clinician to discuss your weight loss goals and get a personalized plan.',
            }}
            cta={
              <Button
                appearance="secondary"
                hasArrow
                to={`/telehealth/sessions/?program=${PROGRAM_TYPE['weight-management-full']}`}
                component={Link}
                onClick={handleClick}
              >
                Schedule
              </Button>
            }
          />
        )}

        {/* show next upcoming appointment */}
        {!isLoadingAppointments &&
          isSuccess &&
          appointment &&
          isAppointmentInTheFuture && (
            <BaseCard
              content={{
                title: <LocalizeDisabled>{title}</LocalizeDisabled>,
                // Overrides the description for upcoming on-demand appointments
                description: setDescription(),
              }}
              cta={
                <FutureAppointmentsCTA
                  appointment={appointment}
                  isManageAppointmentModalEnabled={
                    isManageAppointmentModalEnabled
                  }
                  isAppointmentAsync={isAppointmentAsync}
                  handleClick={handleClick}
                  isOnDemandAppointment={isOnDemandAppointment}
                  parsedTelehealthUrl={parsedTelehealthUrl}
                  canJoinAppointment={canJoinAppointment}
                />
              }
            />
          )}
        {/* show latest completed appointment*/}
        {!isLoadingAppointments &&
          isSuccess &&
          appointment &&
          !isAppointmentInTheFuture && (
            <BaseCard
              content={{
                title: (
                  <LocalizeDisabled>
                    {`${appointment.provider.first_name} ${appointment.provider.last_name}`}
                  </LocalizeDisabled>
                ),
                description:
                  isAppointmentAsync && asyncCarePlanDescription ? (
                    asyncCarePlanDescription
                  ) : (
                    <>
                      Your appointment that was scheduled for{' '}
                      <AppointmentDate
                        appointmentDate={appointment.start_time}
                        showTime={!isOnDemandAppointment}
                        showTimezone
                      />{' '}
                      was {translateStatus(appointment.status)}
                    </>
                  ),
              }}
              cta={
                carePlanId ? (
                  <Button
                    appearance="primary"
                    hasArrow
                    onClick={handleClick}
                    to={`/virtual-care/care-plans?formId=${carePlanId}`}
                    component={Link}
                  >
                    View care plan
                  </Button>
                ) : (
                  isVisible &&
                  !isAppointmentAsync && (
                    <S.ToolTip
                      content="Your provider is still entering your care plan. Check back soon!"
                      position="bottom"
                      arrow="center"
                      animationSpeed="normal"
                    >
                      <Button appearance="primary" isDisabled>
                        View care plan
                        <S.InformationIcon />
                      </Button>
                    </S.ToolTip>
                  )
                )
              }
            />
          )}
      </S.Container>
      {appointment && isManageAppointmentModalEnabled && (
        <ManageAppointmentModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          program={program}
          appointment={appointment}
        />
      )}
    </>
  );
}

export default TelehealthCard;
