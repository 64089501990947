import { datadogRum } from '@datadog/browser-rum';
import analytics from 'common/utils/analytics';
import { removeParamsFromUrl } from 'common/utils/helpers';
import { logError } from 'common/utils/helpers';
import request, { buildInvalidateUrl } from 'common/utils/request';
import * as schema from 'common/utils/schema';
import { ActionT } from 'common/utils/types';
import { normalize, Schema } from 'normalizr';
import { takeEvery, put, call } from 'redux-saga/effects';

import { invalidateAuthRedirectRequest } from './actions';
import {
  HTTP_AUTHENTICATION_SUCCESS,
  HTTP_GET_KITS_SUCCESS,
  HTTP_GET_KIT_RESULT_SUCCESS,
  HTTP_SET_KIT_RESULT_SHARING_ON_SUCCESS,
  HTTP_SET_KIT_RESULT_SHARING_OFF_SUCCESS,
  HTTP_GET_KIT_RESULT_CONTENT_SUCCESS,
  HTTP_GET_USER_DATA_SUCCESS,
  LOADING_ON,
  LOADING_OFF,
} from './constants';

/**
 * array of strings representing action types that shouldn't be monitored
 */
/* istanbul ignore next */
const ignoreActionTypes = ['TOKEN_REFRESH'];

/**
 * checks if an action should be monitored or not
 * @param { Object } action an action object
 * returns boolean as to whether the action should be monitored or not
 */
/* istanbul ignore next */
export function monitorableAction(action: ActionT) {
  return (
    action.type.includes('REQUEST') &&
    ignoreActionTypes.every((fragment) => !action.type.includes(fragment))
  );
}

/**
 * takes the REQUEST state of the action and returns the action name
 * @param { Object } action an action object
 */
function identifyAction(action: ActionT) {
  return action.type.split('_').slice(0, -1).join('_');
}

/**
 * returns the success state of the action type
 * @param { Object } action an action object
 */
export function getSuccessType(action: ActionT) {
  return `${identifyAction(action)}_SUCCESS`;
}

/**
 * returns the failure state of the action type
 * @param { Object } action an action object
 */
export function getFailType(action: ActionT) {
  return `${identifyAction(action)}_FAILURE`;
}

/**
 * handle the success state of http requests
 * @param { String } successType the name of the success type
 * @param { Object } data the response data
 */
// TODO: this seems strange to have this reducer handle every HTTP success throughout the app
// they would be better suited in their respected reducers (Kit results, shared results etc.)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleHttpSuccess(successType: string, data: any) {
  let dataSchema: Schema;
  let dataToNormalize = JSON.parse(JSON.stringify(data));
  switch (successType) {
    case HTTP_AUTHENTICATION_SUCCESS: {
      const { jwt_token, user } = data; // eslint-disable-line camelcase

      // set the user in datadog
      datadogRum.setUser({ id: user.id });

      window.history.pushState(
        '',
        '',
        removeParamsFromUrl(window.location.href, [
          'token',
          'login_event',
          'signup_event',
          'email_event',
        ]),
      );

      // set the jwt and userId in localStorage
      window.localStorage.setItem('jwtToken', jwt_token);
      window.localStorage.setItem('userId', user.id);

      analytics.setUserInfo(user);

      yield put({
        type: successType,
        payload: normalize(user, schema.user),
      });

      return;
    }

    case HTTP_GET_USER_DATA_SUCCESS:
      dataSchema = schema.user;
      break;

    case HTTP_GET_KITS_SUCCESS:
      dataSchema = schema.arrayOfKits;
      break;

    case HTTP_GET_KIT_RESULT_SUCCESS:
    case HTTP_SET_KIT_RESULT_SHARING_ON_SUCCESS:
    case HTTP_SET_KIT_RESULT_SHARING_OFF_SUCCESS:
      dataSchema = schema.kit;
      break;

    case HTTP_GET_KIT_RESULT_CONTENT_SUCCESS:
      dataSchema = schema.arrayOfContentSnippets;
      break;

    default:
      return;
  }

  yield put({
    type: successType,
    payload: dataToNormalize
      ? normalize(dataToNormalize, dataSchema)
      : dataToNormalize,
  });
}

interface HttpFailureError extends Error {
  response: {
    response: {
      status: number;
    };
  };
}

export function redirect(url: string) {
  window.location.assign(url);
}

const redirectErrorPage = (url: string) => {
  Promise.resolve(window.location.assign(url));
};

/**
 * handle the failure state of http requests
 * @param { String } errorType the name of the error type
 * @param { Object } err the error response data
 */
export function* useHandleHttpFailure(
  errorType: string,
  err: HttpFailureError,
) {
  try {
    yield put({
      type: errorType,
      payload: err.response.response,
    });

    const status = err.response.response.status;

    switch (status) {
      case 401:
        yield call(logError, (err as Error).message, {
          errorInfo: '401 Unauthorized during authentication',
          component: 'saga',
          action: errorType,
          originalError: err,
        });
        yield put(invalidateAuthRedirectRequest(buildInvalidateUrl()));
        break;

      case 404:
        yield call(redirectErrorPage, '/404');
        break;

      case 500:
        yield call(logError, (err as Error).message, {
          errorInfo: '500 Internal Server Error during authentication',
          component: 'saga',
          action: errorType,
          originalError: err,
        });
        yield call(redirectErrorPage, '/error');
        break;

      default:
        yield call(logError, (err as Error).message, {
          errorInfo: `Unexpected error (${status})`,
          component: 'saga',
          action: errorType,
          originalError: err,
        });
        yield call(redirectErrorPage, '/error');
        break;
    }
  } catch (e) {
    yield call(logError, (e as Error).message, {
      errorInfo: 'Exception thrown inside useHandleHttpFailure try/catch',
      component: 'saga',
    });
    yield call(redirectErrorPage, '/error');
  }
}

/**
 * action saga that is monitoring for SUCCESS or FAILURE actions to be dispatched
 * NOTE: this is monitoring for HTTP_REQUEST actions only
 * @param { Object } monitoredAction an action object
 * action must follow the pattern: REQUEST, SUCCESS, FAILURE
 *
 */
export function* monitor(monitoredAction: ActionT) {
  if (monitoredAction.type.includes('HTTP')) {
    const { url, options } = monitoredAction.payload;

    try {
      yield put({ type: LOADING_ON });
      // @ts-ignore https://github.com/Microsoft/TypeScript/issues/2983#issuecomment-230404301
      const data = yield call(request, url, options);

      const successType = getSuccessType(monitoredAction);

      yield call(handleHttpSuccess, successType, data);

      yield put({ type: LOADING_OFF });
    } catch (err) {
      yield put({ type: LOADING_OFF }); // Turn loading off if it's on

      yield call(
        useHandleHttpFailure,
        getFailType(monitoredAction),
        err as HttpFailureError,
      );
      // TODO: should we pass along as payload or error?  should we just be consistent with payload always?  style question
      // yield put({ type: getFailType(monitoredAction), error: err });
    }
  } else if (monitoredAction.type.includes('REDIRECT')) {
    yield call(redirect, monitoredAction.payload);
  }
}

/**
 * If an action needs to be monitored the monitor saga will be spawned, otherwise nothing will happen.
 * A monitor saga will spawn if it matches the pattern specified by monitorableAction
 */
export default function* rootSaga() {
  yield takeEvery(monitorableAction, monitor);
}
